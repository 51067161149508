export const tabularColumns = [
  {
    title: 'Gender Breakdown',
    id: '1',
    type: 'uk',
    children: [
      { title: 'Men', id: 2 },
      { title: 'Women', id: 3 },
      { title: 'Non Binary / Others', id: 4 },
    ],
  },
]
export const dataTableHeader = {
  gender_breakdown: {
    desc: 'Mandatory for all Submissions',
    headerdesc: 'Gender Breakdown (Legacy)',
  },
  engagement_age: {
    desc: 'Mandatory for all Submissions',
    headerdesc: 'Gender Age Breakdown',
  },
  age_breakdown: {
    desc: 'Mandatory for all Submissions',
    headerdesc: 'Age Breakdown (Legacy)',
  },
  external_recruitment: {
    desc: 'Recommended for all Submissions, Mandatory for Gold',
    headerdesc: 'External Recruitment by Grade over the last 12 Months',
  },
  attrition: {
    desc: 'Recommended for all Submissions, Mandatory for Gold',
    headerdesc: 'Attrition (Resignation) by Grade over the last 12 Months',
  },
  sickness: {
    desc: 'Recommended for all Submissions, Mandatory for Gold',
    headerdesc: 'Sickness Absence by Grade  over the last 12 Months',
  },
  flexible_working: {
    desc: 'Recommended for all Submissions, Mandatory for Gold',
    headerdesc:
      'Flexible Working Requests by Grade (Submitted and Approved) over the last 12 Months ',
  },
}
export const policyOptions = [
  {
    id: 'yes',
    name: 'Yes',
    policy: 'Yes',
  },
  { id: 'no', name: 'No', policy: 'No' },
]
export const policyDropdown = [
  {
    id: 'yes',
    name: 'Yes',
    option: 'Yes',
  },
  { id: 'no', name: 'No', option: 'No' },
]
