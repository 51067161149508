import { Chip, TextField } from '@mui/material'
import { useState } from 'react'

type Props = {
  LabelName?: string
  onUpdate: (emails: string[]) => void // Callback to pass updated emails to the parent
  error?: any
  setError?: any
}

export const TagActions = ({ onUpdate, error, setError }: Props) => {
  const [items, setItems] = useState<string[]>([])
  const [value, setValue] = useState('')
  // const [error, setError] = useState('')

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (['Enter', 'Tab', ','].includes(event.key)) {
      event.preventDefault()
      const trimmedValue = value.trim()

      if (trimmedValue) {
        const emails = trimmedValue.split(/[\s,]+/).map((email) => email.trim())
        const validEmails = emails.filter(
          (email) => isValid(email) && !items.includes(email)
        )
        const invalidEmails = emails.filter((email) => !isValid(email))
        const duplicateEmails = emails.filter((email) => items.includes(email))

        if (validEmails.length > 0) {
          const updatedItems = [...items, ...validEmails]
          setItems(updatedItems)
          onUpdate(updatedItems) // Notify the parent about the updated list
          setValue('')
        }

        if (invalidEmails.length > 0 || duplicateEmails.length > 0) {
          const errorMessages = []
          if (invalidEmails.length > 0) {
            errorMessages.push(
              `The following emails are invalid: ${invalidEmails.join(', ')}`
            )
          }
          if (duplicateEmails.length > 0) {
            errorMessages.push(
              `The following emails are duplicates: ${duplicateEmails.join(', ')}`
            )
          }
          setError(errorMessages.join(' | '))
        } else {
          setError('') // Clear error if all emails are valid
        }
      }
    }
  }

  const handleDelete = (item: string) => {
    const updatedItems = items.filter((i) => i !== item)
    setItems(updatedItems)
    onUpdate(updatedItems) // Notify the parent about the updated list
  }

  const isValid = (email: string): boolean => {
    if (items.includes(email)) {
      setError(`The email "${email}" is already added.`)
      return false
    }
    if (!/^[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+$/.test(email)) {
      setError(`The email "${email}" is not valid.`)
      return false
    }
    return true
  }

  return (
    <div>
      {/* {LabelName && <label>{LabelName}</label>} */}
      <TextField
        variant="outlined"
        // multiline
        InputProps={{
          // startAdornment: items.map((item) => (
          //   <Chip key={item} label={item} onDelete={() => handleDelete(item)} />
          // )),
          startAdornment: (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
              {items.map((item) => (
                <Chip
                  key={item}
                  label={item}
                  onDelete={() => handleDelete(item)}
                  style={{ margin: '2px' }}
                />
              ))}
            </div>
          ),
        }}
        value={value}
        placeholder="Email ID"
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setError('')
          setValue(e.target.value)
        }}
        className=" w-full input  pr-input  textfield label-text chipStyle focus:outline-1"
        style={{
          minHeight: 'auto',
          maxHeight: '150px', // Limit max height for better appearance
          overflowY: 'auto', // Allow scrolling if chips overflow
        }}
      />
      {error && (
        <p
          style={{ color: 'red', fontSize: '0.875rem' }}
          className="chip-error-message"
        >
          {error}
        </p>
      )}
    </div>
  )
}
