import { downloadData, getData, postData } from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { parseQueryParams } from '../../utilities/parsers'

export const exportOrgDataTable = (type: string, input?: any) => {
  return downloadData(
    `${apiUrl.ORGANISATION_DATATABLES}/${type}/export${parseQueryParams(input)}`
  )
}
export const getDataTables = (orgId?: string, type?: string, params?: any) => {
  return getData(
    `${apiUrl.ORGANISATION_LIST_URL}/${apiUrl.ORGANISATION_DATATABLES}/${type}${parseQueryParams(params)}`
  )
}
export const getOrgDataTables = (type?: string, params?: any) => {
  return getData(
    `${apiUrl.ORGANISATION_DATATABLES}/${type}${parseQueryParams(params)}`
  )
}
export const getPrimaryLocationHistory = () => {
  return getData(`datatable/assign-workforce-locations`)
}
export const updateOrgDataTables = (data: any, type?: string) => {
  return postData(`${apiUrl.ORGANISATION_DATATABLES}/${type}`, data)
}
export const updateWorkForceLocation = (data: any) => {
  return postData(`datatable/assign-workforce-locations`, data)
}
export const exportDataTable = (type: string, params?: any) => {
  return downloadData(
    `${apiUrl.ORGANISATION_LIST_URL}/${apiUrl.ORGANISATION_DATATABLES}/${type}/export${parseQueryParams(params)}`
  )
}
export const getOrgList = () => {
  return getData(`organisations/datatable/organisations-list`)
}
