import React from 'react'
import { IconProps } from '../../../common/types'

const PlayIcon: React.FC<IconProps> = () => {
  return (
    <svg viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0001 14.4001C11.5347 14.4001 14.4001 11.5347 14.4001 8.0001C14.4001 4.46548 11.5347 1.6001 8.0001 1.6001C4.46548 1.6001 1.6001 4.46548 1.6001 8.0001C1.6001 11.5347 4.46548 14.4001 8.0001 14.4001ZM7.64386 5.73446C7.39837 5.5708 7.08274 5.55554 6.82261 5.69476C6.56249 5.83397 6.4001 6.10506 6.4001 6.4001V9.6001C6.4001 9.89513 6.56249 10.1662 6.82261 10.3054C7.08274 10.4447 7.39837 10.4294 7.64386 10.2657L10.0439 8.66574C10.2664 8.51737 10.4001 8.26758 10.4001 8.0001C10.4001 7.73262 10.2664 7.48283 10.0439 7.33446L7.64386 5.73446Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PlayIcon
