import React from 'react'
import { IconProps } from '../../../common/types'

const EyeClose: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.768 15.9255C11.1953 16.0341 10.6043 16.0909 10.0001 16.0909C5.7768 16.0909 2.20186 13.3152 1 9.48863C1.32719 8.44689 1.83024 7.48306 2.47436 6.6319M7.99881 7.48784C8.51088 6.9758 9.21827 6.65909 9.9996 6.65909C11.5624 6.65909 12.8291 7.92587 12.8291 9.48863C12.8291 10.27 12.5124 10.9773 12.0004 11.4894M7.99881 7.48784L12.0004 11.4894M7.99881 7.48784L4.8962 4.38523M12.0004 11.4894L15.1033 14.5924M4.8962 4.38523L1.51097 1M4.8962 4.38523C6.36739 3.43675 8.11946 2.88636 9.99998 2.88636C14.2233 2.88636 17.7982 5.66206 19 9.48863C18.3333 11.6114 16.9362 13.4108 15.1033 14.5924M15.1033 14.5924L18.4882 17.9773"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EyeClose
